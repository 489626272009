// ** Initial user ability
// export const initialAbility = [
//   {
//     action: 'read',
//     subject: 'Auth'
//   }
// ]

export const initialAbility = 
[
  {
    action: 'manage',
    subject: 'all'
  }
]

// export const _ = undefined
export const _ =  [
  {
    action: 'manage',
    subject: 'all'
  }
]
